<template>
  <div id="app" v-if="ready">
    <div class="searchform">
      <ul>
          <li class="searchbar">
            <h5>Find a Barrister</h5>
            <div class="searchfield">
              <div class="dropdown-container">
                <input type="text" id="dropdown-toggle" @input="findBarrister" class="dropdown-input" placeholder="Barrister's name" @focus="showDropdown" @blur="hideDropdown">
                <div class="dropdown-content">
                  <div class="dropdown-item" v-for="(list,i) of listOfBarrister" :key="i" @click="redirectTo(list)">
                    <img :src="list.ThumbnailPath" alt="List Image" class="barrister-image" />
                    <span class="barrister-name">{{ list.FullName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="advanced">
          <h5> <a :href="config.Settings.barristerLink" target="_blank">Advanced Search</a></h5>
          <ul class="advancedlinks">
              <li><a :href="config.Settings.barristerLink" target="_blank" class="tablink">Find a Barrister</a></li>
              <li><a :href="config.Settings.mediatorLink" target="_blank" class="tablink">Find a Mediator</a></li>
              <li><a :href="config.Settings.arbitratorLink" target="_blank" class="tablink">Find an Arbitrator</a></li>
          </ul>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mtapi from './mtapi'
import ui from "./components/ui";
export default {
  name: 'display',
  props: ['ContentKey', 'ContentItemKey'],
  components: {...ui},
  data () {
    return {
      ready: false,
      config: {
        Settings: {}
      },
      listOfBarrister: []

    }
  },
  async created () {
    // load configuration for iPart
    let response = await mtapi.axios.get(
      `/api/ContentItem?ContentKey=${this.ContentKey}&ContentItemKey=${this.ContentItemKey}`
    )
    if (response?.data?.$type?.startsWith(`Asi.Soa.Core.DataContracts.PagedResult`))
      this.config = response?.data?.Items?.$values[0]?.Data
    else
      this.config = response?.data
    // once all data loaded ok to mount the component
    this.ready = true
  },
  methods: {
    async findBarrister (event) {
      if(event.target.value && event.target.value.length>2){
        let resp = await this.getIQAData(`${this.config.Settings.barristerIQA}&parameter=${event.target.value}`,null)
      console.log(resp)
        this.listOfBarrister=resp.map(x=>{return {ID:x.ID,FullName: x.FullName,ThumbnailPath: x.ThumbnailPath}})
      
      }
    },
    showDropdown(){
      document.getElementsByClassName('dropdown-content')[0].style.display='grid'
    },
    hideDropdown(){
      setTimeout(()=>{
        document.getElementsByClassName('dropdown-content')[0].style.display='none'
      },500)
     
    },
    redirectTo(item){
      window.open(this.config.Settings.profileLink.replace("[ID]", item.ID), '_blank');
    },
    async getIQAData(url){
      let iqaurl		   =	`/api/iqa?QueryDocumentVersionKey=${url}&Limit=500`;
      let content 	   = 	await mtapi.axios.get(iqaurl);
      let queryResult  =  new mtapi.ImisEntityCollection(content?.data);
      let resp         =  queryResult.Entities
      if(content.data.TotalCount >500){
        let count      =  Math.floor(content.data.TotalCount/500)
        for (let index = 1; index <= count; index++) {
          let iqaurlloop		  =	  `/api/iqa?QueryDocumentVersionKey=${url}&Limit=500&Offset=${index*500}`;
          let contentloop 	  = 	await mtapi.axios.get(iqaurlloop);
          let queryResultloop =   new mtapi.ImisEntityCollection(contentloop?.data);
          let resploop        =   queryResultloop.Entities
          resp.concat(resploop)
        }
      }
      return resp
    },
  }
}
</script>

<style scoped lang="scss">
#app {
  color: inherit;
  .dropdown-container { position: relative; width: 99%;}

    .dropdown-input {
      border-radius: 2em;
    }

    .dropdown-content {
      display: none;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 21px;
      position: absolute;
      z-index: 9;
      top: 100%;
      left: 0;
      width: 100%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fffffc;
      padding: 32px 40px;
    }
    .dropdown-item {
      padding: 10px;
      display: grid;
      gap: 10px;
      grid-template-columns: 96px 1fr;
      cursor: pointer;
    }

    .dropdown-item:last-child {
      border-bottom: none;
    }

    .dropdown-item:hover {
      background-color: #3498db;
      color: #fff;
    }
    .dropdown-item .barrister-image{
      width: 96px;
      height: 96px;
    }
    .barrister-name{
      padding-top: 1rem;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      .dropdown-content {
        grid-template-columns: 1fr; /* Change to 1 column for mobile view */
      }
    }
}
</style>
